import fetch from 'unfetch';
import { Component } from "preact";
import Vehicle from '@components/vehicle';

// Airtable stuff
const APPID = "apph7uffeLRRNbRSv";
const APPKEY = "keyHvV5Qc2rnSunz6";

const API = `https://api.airtable.com/v0/${APPID}/vehicle_inventory`;
const Authorization = `Bearer ${APPKEY}`;
const headers = { Authorization };

let required = ['stock_number', 'make', 'model', 'description', 'listed_price', 'year', 'mileage', 'fuel_type', 'transmission_type', 'body_type', 'stock_number'];

function hasRequired(obj) {
    return required.every(key => obj[key] !== void 0) && (obj.photos || []).length > 0;
}

export default class Vehicles extends Component {
  state = {
    items: []
  }

  componentWillMount() {
    fetch(API, { headers }).then(r => r.json() ).then(res => {
      res.records.sort((a,b) => a.createdTime.localeCompare(b.createdTime));
      let items = res.records.map(o => o.fields).filter(hasRequired);
      this.setState({ items });
    });
  }

  render(_, state) {
    return (
      <div id="vehicles">

      <div class="section">
        <div class="container" id="vehiclesList">
          <h2>Repairable Vehicles</h2>
          { state.items.map(Vehicle) }
        </div>
      </div>

    </div>
  );
  }

}
