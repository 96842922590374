export default function Sell(props) {
  return (
    <div id="sell-vehicle">

      <div class="section">
        <div class="container">
          <h2>Sell a Vehicle</h2>
          <script src = "https://static.airtable.com/js/embed/embed_snippet_v1.js" > </script>
          <iframe class="airtable-embed airtable-dynamic-height" src="https://airtable.com/embed/shr2fMfIPTaMxlbkf?backgroundColor=cyan" frameborder="0" onmousewheel="" width="100%" height="2559" style="background: transparent; border: 0px solid #ccc;"></iframe>
        </div>
      </div>

    </div>
  );
}
