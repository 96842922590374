import { h } from 'preact';

export default function Vehicle(props) {

  return (
    <div class="row vehicleRow">
      <div class="col-md-8 mb-6 mb-md-0">
        <a href={ props.photos[0].url } data-provide="lightbox"><img src={ props.photos[0].url } alt="" /></a>
      </div>

      <div class="col-md-4" id="vehiclesList">
        <a name={props.stock_number}><h5>{ props.make } { props.model }</h5></a>
        <p>{ props.description }</p>
        <ul class="project-detail mt-7">
          <li>
            <strong>Price:</strong>
            <span>${ props.listed_price }</span>
          </li>
          <li>
            <strong>Year:</strong>
            <span>{ props.year }</span>
          </li>
          <li>
            <strong>Mileage:</strong>
            <span>{ props.mileage }</span>
          </li>
          <li>
            <strong>Fuel:</strong>
            <span>{ props.fuel_type }</span>
          </li>
          <li>
            <strong>Transmission:</strong>
            <span>{ props.transmission_type }</span>
          </li>
          <li>
            <strong>Body:</strong>
            <span>{ props.body_type }</span>
          </li>
          <li>
            <strong>Stock #:</strong>
            <span>{ props.stock_number }</span>
          </li>
          <li>
            <strong>
              <span><a href={"mailto:" + SHOP_EMAIL + "?subject=Inquiry%20About%20your%20vehicle"}>Contact us</a></span>
            </strong>
          </li>
        </ul>
      </div>
    </div>
  );
}
