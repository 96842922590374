import { h } from "preact"
import { Router } from "preact-router"

import Home from "@pages/index"
import Parts from "@pages/part-sales"
import Vehicles from "@pages/vehicle-sales"
import Sell from "@pages/sell-vehicle"

export default function App() {
  return(
    <div id="app">
        <Router>
          <Home path="/" />
          <Parts path="/part-sales" />
          <Vehicles path="/vehicle-sales" />
          <Sell path="/sell-vehicle" />
        </Router>
    </div>
  )
}
