export default function Home(props) {
  return (
    <div id="home">

    <header class="header h-fullscreen" style="background-image: linear-gradient(180deg, #ffffff 0%, #ffffff 95%, #ffffff 100%);">
      <div class="container">
        <div class="row align-items-center h-100">

          <div class="col-md-6 ml-auto ">
            <img class="logo" src={SHOP_LOGO} alt="company-logo"/>
          </div>

          <div class="col-md-5 float-sm-right header-info">
            <h1>{SHOP_SLUG}</h1>
            <p class="lead mt-5 mb-8">{SHOP_TYPE} in {SHOP_CITY}, {SHOP_STATE}</p>
            <a class="btn btn-round btn-primary" href={"tel:" + SHOP_PHONE_NUMBER}>Call {SHOP_PHONE_NUMBER}</a>
            <a class="btn btn-round btn-outline-secondary" href={"https://www.google.com/maps/search/" + SHOP_STREET_1 + "," + SHOP_CITY + "," + SHOP_STATE} target="_blank">Get Directions</a>

          </div>



        </div>
      </div>
    </header>

    <main class="main-content">

      <section class="section py-0 bg-light">
        <div class="row no-gutters">

          <div class="col-10 col-md-4 mx-auto align-self-center py-8">
            <p class="text-uppercase small opacity-50 fw-600 ls-1">Location</p>
            <h5>{SHOP_CITY}, {SHOP_STATE}</h5>
            <br/>
            <p>{SHOP_STREET_1}<br/>{SHOP_CITY}, {SHOP_STATE}, {SHOP_ZIP}</p>
            <p>Phone: {SHOP_PHONE_NUMBER}<br/>Email: {SHOP_EMAIL}</p>
          </div>

          <div class="col-md-6 map-section">
            <a id="shopMap"></a>
            <div class="h-100" data-provide="map" data-lat={SHOP_LAT} data-lng={SHOP_LON} data-marker-lat={SHOP_LAT} data-marker-lng={SHOP_LON} data-info="&lt;strong&gt;Our location&lt;/strong&gt;&lt;br&gt;Castro Street, San Francisco, CA" data-style="light" style="min-height: 350px">
            </div>
          </div>

        </div>
      </section>


        <section class="section">
          <div class="container">
            <header class="section-header">
              <a id="shopServices"></a>
              <small>Services</small>
              <h2>We are proud to offer the following services</h2>
              <hr/>
              <p class="lead">Contact us to learn more</p>
            </header>

            <div class="row gap-y text-center">

              <div class="col-md-6 col-xl-4 feature-1">
                <p class="feature-icon text-danger"><i class={SHOP_SERVICE_1_ICON}></i></p>
                <h5>{SHOP_SERVICE_1_TITLE}</h5>
                <p>{SHOP_SERVICE_1_TEXT}</p>
              </div>


              <div class="col-md-6 col-xl-4 feature-1">
                <p class="feature-icon text-success"><i class={SHOP_SERVICE_2_ICON}></i></p>
                <h5>{SHOP_SERVICE_2_TITLE}</h5>
                <p>{SHOP_SERVICE_2_TEXT}</p>
              </div>


              <div class="col-md-6 col-xl-4 feature-1">
                <p class="feature-icon text-info"><i class={SHOP_SERVICE_3_ICON}></i></p>
                <h5>{SHOP_SERVICE_3_TITLE}</h5>
                <p>{SHOP_SERVICE_3_TEXT}</p>
              </div>

            </div>

          </div>
        </section>

      <div class="section">
        <div class="container">

          <div class="row gap-y">
            <div class="col-md-3 ml-auto">
                <div class="bg-gray h-full p-3 footer-box">
                  <h3>Proud Member of</h3>
                  <a href="http://www.a-r-a.org" target="_blank"><img src="/assets/img/a-r-a-logo.png" alt="ara"/></a>
                  <a href="http://www.scada1.com" target="_blank"><img src="/assets/img/scada_logo.png" alt="scada"/></a>
                </div>
            </div>

            <div class="col-md-3 ml-auto">
              <div class="bg-gray h-full p-3 footer-box">
                <h3>Shop Parts Online</h3>
                <a href="https://www.ebay.com/usr/allcarandtruckdismantlers" target="_blank"><img src="/assets/img/ebay-logo.png" alt="ebay"/></a>
                <a href="http://search2154.used-auto-parts.biz/inventory/retailF.htm" target="_blank"><img src="/assets/img/car-part-logo.png" alt="car-part"/></a>
              </div>
            </div>



            <div class="col-md-5 ml-auto">
              <div class="bg-gray h-full p-5">
              <h3>Visit Us</h3>
                <p>{SHOP_HOURS}</p>
                <p class="lead">{SHOP_STREET_1}<br/>{SHOP_CITY}, {SHOP_STATE}, {SHOP_ZIP}</p>
                <div>
                  <p class="d-inline-block text-primary" title="Email">E: {SHOP_EMAIL}</p>
                </div>
                <div>
                  <p class="d-inline-block text-primary" title="Phone">P: {SHOP_PHONE_NUMBER}</p>
                </div>
                <div>
                  <p class="d-inline-block text-lighter" title="Dismantler">Dismantler #: {DISMANTLER_NUMBER}</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </main>


  </div>

  );
}
