import { h, render } from 'preact';
import App from '@components/app';

let elem = document.querySelector('#app');
let root = render(<App/>, elem, elem.firstElementChild);

if (process.env.NODE_ENV === 'development' && module.hot) {
    require('preact/debug');
    module.hot.accept('@components/app.js', New => {
        New = require('@components/app').default;
        root = render(<New />, elem, root);
    });
}
